import React from "react"

import Layout from "../../layouts/Fi"

import SEO from "../../components/SEO"

import Envelope from "../../icons/envelope.svg"
import Phone from "../../icons/phone.svg"
import Marker from "../../icons/marker.svg"

const OtaYhteyttaPage = () => (
  <Layout title="Ota yhteyttä">
    <SEO keywords={[`ota`, `yhteyttä`]} title="Ota yhteyttä" />

    <section className="flex flex-wrap justify-center mb-12">
      <div className="w-full lg:w-3/4">
        <div
          className="flex flex-wrap bg-orange shadow-orange rounded-lg"
          style={{
            background: "linear-gradient(102.44deg, #F87060 0%, #FFD66C 75%)",
          }}
        >
          <div className="w-full sm:w-1/2 md:w-2/5 text-white rounded-lg px-8 py-12">
            <div className="flex mb-4">
              <div className="w-10 mr-6">
                <Envelope />
              </div>
              <div className="w-auto">
                <h3 className="mb-4">Sähköposti</h3>
                <p>
                  <a href="mailto:kopernicus@kopernicus.fi">
                    kopernicus@kopernicus.fi
                  </a>
                </p>
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-10 mr-6">
                <Phone />
              </div>
              <div className="w-auto">
                <h3 className="mb-4">Puhelinnumero</h3>
                <p>
                  <a href="tel:+358503398198">+358 50 3398198</a>
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="w-10 mr-6">
                <Marker />
              </div>
              <div className="w-auto">
                <h3 className="mb-4">Osoite</h3>
                <p className="mb-0">
                  Lähdekuja 6 A 10
                  <br />
                  13210 Hämeenlinna
                  <br />
                  FINLAND
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-3/5 bg-white rounded-lg px-8 py-12">
            <form
              name="Yhteydenotto - Fi"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/fi/kiitos/"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Yhteydenotto - Fi" />
              <label className="block mb-2" htmlFor="name">
                Nimi
              </label>

              <input
                className="w-full mb-6 form-input"
                id="name"
                name="Nimi"
                placeholder="Jaska Jokunen"
                type="text"
              />

              <label className="block mb-2" htmlFor="email">
                Sähköposti
              </label>

              <input
                className="w-full mb-6 form-input"
                id="email"
                name="Email"
                placeholder="jaska@jokunen.fi"
                type="email"
              />

              <label className="block mb-2" htmlFor="message">
                Viesti
              </label>

              <textarea
                className="w-full mb-6 form-textarea"
                id="message"
                name="Viesti"
                placeholder="Kirjoita viestisi tähän"
                rows="8"
              />

              <button
                type="submit"
                className="bg-orange shadow-orange text-white tracking-wider leading-5 px-6 py-4 font-display font-medium rounded-md"
              >
                Lähetä
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default OtaYhteyttaPage
